import React from 'react';
import Header from 'src/components/parts/Header';
import {Helmet} from 'react-helmet';

const Error = () => {
    return (
		<div className="not_found">
		    <Header />
		    <div className="container">
				<h1>404 Error - Page Not Found</h1>
				<Helmet>
					<title>Page Not Found</title>
					<meta name="robots" content="noindex" />
					<meta name="googlebot" content="noindex" />
				</Helmet>
			</div>
		</div>
    );
}

export default Error;
 