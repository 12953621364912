import React from 'react';

const Contact = () => {
    return (
       <div className="contact">
			<h1>Contact</h1>
			<p>317 S Broadway #21 Los Angeles, CA 90013</p>
			<p>Phone: 714-810-6228 </p>
			<p>Email: contact@livefemdomporn.com</p>
			<p>Twitter: @livefemdomporn</p>
       </div>
    );
}

export default Contact;