import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCircle, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

class MobileMenu extends React.Component {

	constructor(props) {

		super(props);
		this.state = {isOpen: false }
		this.handleMenuButton = this.handleMenuButton.bind(this);

	}

	//	update menu state
	handleMenuButton(event) {
		this.setState({ isOpen: !this.state.isOpen });
	}

	renderMobileMenuOpened = () => {

		return (
			<div>
				<div className="space_fixer"></div>
				<div className="menu_wrapper">
					<nav id="menu">
						<div className="menu_close" onClick={this.handleMenuButton}><FontAwesomeIcon icon={faTimes} /></div>
						<div className="menu">
							<div className="menu_user">
								<div className="menu_id">
									<div className="profile_id">
										<FontAwesomeIcon icon={faUser} />
										<div className="id">Guest7539</div>
										<div className="balance">Balance: 0 tokens</div>
									</div>
									<a href="/tokens/" className="tokens">TOKENS</a>
								</div>
							</div>
							<div className="menu_title"><FontAwesomeIcon icon={faCircle} /> Who's online now ?</div>
							<div className="menu_links">
								<NavLink to="/" activeClassName="active" onClick={this.handleMenuButton} exact>Home</NavLink>
								<NavLink to="/category/teen/" activeClassName="active" onClick={this.handleMenuButton}>Teen</NavLink>
								<NavLink to="/category/milf/" activeClassName="active" onClick={this.handleMenuButton}>MILF</NavLink>
								<NavLink to="/category/mature/" activeClassName="active" onClick={this.handleMenuButton}>Mature</NavLink>
								<NavLink to="/category/foot-fetish/" activeClassName="active" onClick={this.handleMenuButton}>Foot Fetish</NavLink>
								<NavLink to="/category/femdom-cams/" activeClassName="active" onClick={this.handleMenuButton}>Femdom Cams</NavLink>
								<NavLink to="/category/live-femdom/" activeClassName="active" onClick={this.handleMenuButton}>Live Femdom</NavLink>
								<NavLink to="/category/dominatrix-cams/" activeClassName="active" onClick={this.handleMenuButton}>Dominatrix Cams</NavLink>
								<NavLink to="/category/mistress-cams/" activeClassName="active" onClick={this.handleMenuButton}>Mistress Cams</NavLink>
								<NavLink to="/contact/" activeClassName="active" onClick={this.handleMenuButton}>Contact</NavLink>
							</div>
							<div className="menu_footer">
								<p className="copyright">Copyright &copy; 2020 livefemdomporn.com</p>
							</div>
						</div>
					</nav>
					<div className="overlay" onClick={this.handleMenuButton}></div>
				</div>
			</div>
		)
	}
	
	renderMobileMenuClosed = () => {

		return(
			<div id="menuButton" onClick={this.handleMenuButton}>
				<FontAwesomeIcon icon={faBars} />
			</div>
		)

	}

	render() {

		return (
			<div>
				{this.state.isOpen ?  this.renderMobileMenuOpened() : this.renderMobileMenuClosed()}
			</div>
		);
	}

}

export default MobileMenu;