import React, { Component } from 'react';
import UserContainer from 'src/components/parts/UserContainer';
import {Helmet} from 'react-helmet';
import { connect } from "react-redux";
import { getChatrooom, setText, setUsers } from "src/cms.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faPhotoVideo, faCircle, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Header from 'src/components/parts/Header';

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  meta_title: state.meta_title,
	  meta_description: state.meta_description,
	  content: state.content,
	  currentUser:state.currentUser,
	  top_text: state.top_text
	};
};

//	Main Home Class
class Chatroom extends Component {

	constructor(props) {

		super(props);

		var width = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
		var height = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;
		
		this.state = {width: width, height: height}

		this.GenerateRandomWeight = this.GenerateRandomWeight.bind(this);
		this.GenerateRandomHeight = this.GenerateRandomHeight.bind(this);
		this.GenerateRandomAge = this.GenerateRandomAge.bind(this);

		this.handleGoBack = this.handleGoBack.bind(this);
		this.handleGoNext = this.handleGoNext.bind(this);

	}

	//	On Load
	componentDidMount() {

		getChatrooom( this.props );

		setText(this.props);

		
	}


	componentDidUpdate(prevProps, prevState) {

		//	Make sure there was a change in pages
		if( this.props.location.pathname !== prevProps.location.pathname) {

			this.props.dispatch({type: 'GET_ALL_USERS_RESET'});	// after getting initial users will allow for when scroll happens will get all users again

			this.props.dispatch({type: 'RESET_SHOW_USERS'});
			
			getChatrooom(prevProps)

			setUsers(prevProps)

			setText(prevProps)

		} else {

			//window.scrollTo({ top:0, behavior: 'smooth' });
			window.scrollTo({ top:0 });

		}
		
	}


	// chatbox redirect
	handleSendButton = (event) => {
		if(event.key === 'Enter'){
			event.preventDefault();
			window.location = this.props.currentUser.live_room_url;
		}
	}

	// handle go back button for chatroom
	handleGoBack() {
		let path = "/";
		this.props.history.push(path);
	}
	
	handleGoNext() {

		//If already on the roulette page need to do a refresh.
		if( window.location.pathname === '/roulette/' ) {

			window.location.reload(false);

		} else {

			this.props.history.push('/roulette/');

		}
		
	}


	GenerateRandomWeight = () => {
		return Math.floor(Math.random() * (65 - 48)) + 48;
	}
	GenerateRandomHeight = () => {
		return Math.floor(Math.random() * (180 - 160)) + 160;
	}
	GenerateRandomAge = () => {
		return Math.floor(Math.random() * (28 - 19)) + 19;
	}

	renderImg = () => {
		// Check if it is empty.
		if( typeof this.props.currentUser.picture_url === 'undefined' ) {
			return '';
		}

		// Image is there return.
		return(
			<img src={document.location.protocol+'//'+document.domain+this.props.currentUser.picture_url} className="chatroom-profile-picture"></img>
		);
	}

	render() {
	//console.log( document.location.protocol+'//'+document.domain+this.props.currentUser.iframe );
		console.log( window.location.pathname );

	  return (
		<div>
		    <Header />
		    <div className="container">
				<div className="chatroom">
					<div className="chatroom-go-back" onClick={this.handleGoBack}><FontAwesomeIcon icon={faArrowLeft} /></div>
					<div className="chatroom-go-next" onClick={this.handleGoNext}><FontAwesomeIcon icon={faArrowRight} /></div>
					<iframe src={this.props.currentUser.iframe} height={this.state.width * .75} width={this.state.width} title={this.props.currentUser.username+'\'s Chat Room'} />
				</div>
				<div>
					<div className="chatroom-contentwrap">
						<div className="chatroom-bar">
							<div className="chatroom-bar-model"><h1><FontAwesomeIcon icon={faCircle} /> {this.props.currentUser.name}</h1></div>
							<div className="chatroom-bar-btns">
								<a href={this.props.currentUser.live_room_url} title="Go Full Screen" className="chatroom-bar-btn"><FontAwesomeIcon icon={faExpand} /></a>
								<a href={this.props.currentUser.live_room_url} title="Model Gallery" className="chatroom-bar-btn"><FontAwesomeIcon icon={faPhotoVideo} /></a>
								<a href={this.props.currentUser.live_room_url} title="Get Free Tokens" className="chatroom-bar-btn credits">TOKENS</a>
							</div>
						</div>
						<div className="chatroom-info">
							<div><span>Age:</span><p>{this.props.currentUser.age === 0 ? this.GenerateRandomAge() : this.props.currentUser.age} years old</p></div>
							<div><span>Ethnicity:</span><p>{this.props.currentUser.ethnicity === 'none' ? 'Ask' : this.props.currentUser.ethnicity}</p></div>
							<div><span>Speaks:</span><p>{this.props.currentUser.languages}</p></div>
							<div><span>Online Time:</span><p>{this.props.currentUser.online_time === '0' || this.props.currentUser.online_time > 1000 || typeof( this.props.currentUser.online_time ) === 'undefined' ? 'Hidden' : this.props.currentUser.online_time+' minutes'}</p></div>
							<div><span>Body Type:</span><p>{this.props.currentUser.body_type === 'none' ? 'Average' : this.props.currentUser.body_type}</p></div>
							<div><span>Tit Size:</span><p>{this.props.currentUser.breast_size === 'none' ? 'Medium' : this.props.currentUser.breast_size}</p></div>
							<div><span>Hair Color:</span><p>{this.props.currentUser.hair_color === 'none' ? 'Ask' : this.props.currentUser.hair_color}</p></div>
							<div><span>Hair Length:</span><p>{this.props.currentUser.hair_length === 'none' ? 'Ask' : this.props.currentUser.hair_length}</p></div>
							<div><span>Eye Color:</span><p>{this.props.currentUser.eye_color === 'none' ? 'Ask' : this.props.currentUser.eye_color}</p></div>
							<div><span>Weight:</span><p>{this.props.currentUser.weight_kg === '0' || typeof( this.props.currentUser.weight_kg ) === 'undefined' ? 'N/A' : this.props.currentUser.weight_kg + ' kg'} </p></div>
							<div><span>Height:</span><p>{this.props.currentUser.height_cm === '0' || typeof( this.props.currentUser.height_cm ) === 'undefined' ? 'N/A' : this.props.currentUser.height_cm + ' cm'} </p></div>
							<div><span>Chatters:</span><p>{this.props.currentUser.chatters === 0 ? 'Private' : this.props.currentUser.chatters}</p></div>
							<div><span>Country Code:</span><p>{this.props.currentUser.country_code === '' ? 'US' : this.props.currentUser.country_code}</p></div>
							<div><span>What I Like:</span></div>
							<div><p>{this.props.currentUser.turn_ons == '' ? 'Slaves and cash' : this.props.currentUser.turn_ons}</p></div>
							<div><span>Turn Offs:</span></div>
							<div><p>{this.props.currentUser.turn_offs == '' ? 'Stupid guys' : this.props.currentUser.turn_offs}</p></div>
							<div><span>About Me:</span></div>
							<div><p>{this.props.currentUser.bio == '' ? 'I love to dominate men in private shows. Join me!' : this.props.currentUser.bio}</p></div>
							<div><span>Profile Picture:</span></div>
							<div><a href={this.props.currentUser.live_room_url}>{this.renderImg()}</a></div>
						</div>
						<h4>More Models Like <span className="highlight">{this.props.currentUser.name}</span></h4>
						<UserContainer showOrderBox={false} />
						<Helmet>
							<title>{this.props.meta_title}</title>
							<meta name="description" content="{props.meta_description}" />
						</Helmet>
					</div>
				</div>
			</div>
		</div>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Chatroom);