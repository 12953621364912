import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { api_url } from "src/cms.js";

//
//	Smart search teen keyword ( search for 18 -19)
//
class SearchBox extends React.Component {

	constructor(props) {
		super(props);
		this.state = {Search: '', searchSuggestion:[]}		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	//
	//	Update input text change
	//
	handleChange(event) {
		
		this.setState({ [event.target.name]: event.target.value});
		
		if( event.target.value.length >= 4 ) {

			fetch( api_url + '/ajax/search-helper/?value='+event.target.value)
			.then( results => { return results.json() })
			.then( data => {
				
				document.getElementById('search_suggestions').style.width = document.getElementById('search_input').offsetWidth + 'px';

				if( data.length > 0 ) {

					this.setState({searchSuggestion: data });
					
					document.getElementById("search_suggestions").style.display = "block";

				} else {

					document.getElementById("search_suggestions").style.display = "none";

				}
				
			})
		}
	}


	//	Submit form
	handleSubmit(event) {

		event.preventDefault();
		
		window.location="/search/"+this.state.Search.replace(/ /g, '-')+'/';
		
	}

	// Have to set as html or else the <span> shows as text instead of html tag.
	highlightMatch(text) {

		return{ __html: text.replace( this.state.Search, "<span>"+this.state.Search+"</span>" )}
		
	}

	render() {
		return (
			<div className="search_wrap">
				<form id="search" onSubmit={this.handleSubmit}>
					<input type="text" name="Search" className="search_input" id="search_input" autoComplete="off" placeholder="Search ..." onChange={this.handleChange} />
					<button id="search_button" title="Search">
						<FontAwesomeIcon icon={faSearch} fontSize="16px" color="#f3f3f3" />
					</button>
				</form>
				<ul id="search_suggestions">
				{this.state.searchSuggestion.map( (text) =>
					<li key={text}>
						<a href={'/search/'+text.replace(' ', '-')+'/'} dangerouslySetInnerHTML={this.highlightMatch(text)}></a>
					</li>
				)}
				</ul>
			</div>
		);
	}
}


export default SearchBox;
