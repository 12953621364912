//
//	Site Suggestions / links to other sites.
//
import React from 'react';
import { api_url } from "src/cms.js";

//
//	Smart search teen keyword ( search for 18 -19)
//
class Friends extends React.Component {

	constructor(props) {
		super(props);

		this.state = {links:''}
		
	}

	//	On Load
	componentDidMount() {

		fetch( api_url + '/ajax/get-friends/?id=231' )
		.then( results => { 
			
			return results.json() })

		.then( data => {
			
			this.setState({links:data.text});
			
		})
	
	}

	render() {
		return (
			<div dangerouslySetInnerHTML={{__html: this.state.links}}></div>
		);
	}
}

/*
We are just loading the flat file and handling design in links CMS..
{this.state.links.map( (text) =>
	<a href={text.link}>{text.anchor}</a>
)}
*/

export default Friends;